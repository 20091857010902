import { Select as AntSelect } from 'antd';

export default function Select({ options, value, onChange }) {

  return (
    <AntSelect
      options={options}
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}/>
  );
}