import { ScatterplotLayer, PathLayer } from '@deck.gl/layers';
import { DataFilterExtension } from '@deck.gl/extensions';
import { ColorPalettes } from 'CityData/ColorPalettes'
import { CityData } from 'CityData/CityData';
import { TFilteredNode, TFilteredSegment } from 'CityData/CityDataTypes';

export function SpeedDotLayer({ data, filters, settings }: { data: CityData, filters: any, settings: any }) {

  const colorPalette = ColorPalettes[settings.nodePalette];
  let getColor, getRadius = null
  if (settings.nodeSpeedMode === 'range_absolute') {
    getColor = (d: TFilteredNode) => (colorPalette.getRGBA(1-d.speedRangeScaled * 5 * settings.nodeSpeedColorFactor));
    getRadius = (d: TFilteredNode) => (settings.nodeWidth + d.speedRangeScaled * 100 * settings.nodeScale);
  } else if (settings.nodeSpeedMode === 'range_relative') {
    getColor = (d: TFilteredNode) => (colorPalette.getRGBA((1 - d.speedRangePercent) * settings.nodeSpeedColorFactor));
    getRadius = (d: TFilteredNode) => (settings.nodeWidth + d.speedRangePercent * 100 * settings.nodeScale);
  } else if (settings.nodeSpeedMode === 'percentile') {
    getColor = (d: TFilteredNode) => (colorPalette.getRGBA((d.avrSpeed) / 30 * settings.nodeSpeedColorFactor));
    getRadius = (d: TFilteredNode) => (settings.nodeWidth + (30 - d.avrSpeed) / 30 * 10 * settings.nodeScale);
  }

  return new ScatterplotLayer({
    id: 'speedNodeLayer',
    data: data.filteredNodes,
    opacity: 1,
    getPosition: (d: TFilteredNode) => d.coords,
    getFillColor: getColor,
    getRadius: getRadius,
    radiusUnits: 'meters',
    radiusMinPixels: 1,
    radiusMaxPixels: 40,
    antialiasing: false,
    pickable: true,
    autoHighlight: true,
    highlightColor: (d: any) => (d.object.routeCount) === 0 ? [160, 200, 255, 128] : [255, 255, 255, 128],
    parameters: {
      depthTest: true
    },
    updateTriggers: {
      getFillColor: [data.filteredNodes, filters, settings],
      getRadius: [data.filteredNodes, filters, settings],
    },
  });
};

export function SpeedLineLayer({ data, filters, settings }: { data: CityData, filters: any, settings: any }) {
  //console.log("SpeedLineLayer", settings);
  /*
  const lineWidth = settings.lineWidth;
  const lineScale = settings.lineScale;
  const widthFactor = lineWidth * lineScale;
  const getWidth = (d: TFilteredSegment) => lineWidth + widthFactor * (d.speedRangePercent);

  const colorPalette = ColorPalettes[settings.palette];
  const factor = settings.colorFactor;
  const getColor = (d: TFilteredSegment) => (d.routeCount === 0) ? [0, 32, 48, 255] : colorPalette.getRGBA(d.speedRangePercent * factor);
*/

  const colorPalette = ColorPalettes[settings.nodePalette];
  let getColor, getWidth = null
  if (settings.nodeSpeedMode === 'range') {
    getColor = (d: TFilteredSegment) => (colorPalette.getRGBA(d.speedRangeScaled * settings.nodeSpeedColorFactor));
    getWidth = (d: TFilteredSegment) => (settings.nodeWidth + d.speedRangeScaled * 100 * settings.nodeScale);
  } else if (settings.nodeSpeedMode === 'percentile') {
    getColor = (d: TFilteredSegment) => (colorPalette.getRGBA((d.avrSpeed) / 30 * settings.nodeSpeedColorFactor));
    getWidth = (d: TFilteredSegment) => (settings.nodeWidth + (d.avrSpeed) / 30 * 10 * settings.nodeScale);
  }
  getColor = (d: TFilteredSegment) => (colorPalette.getRGBA((d.avrSpeed) / 30 * 1));

  return new PathLayer({
    id: 'speed',
    data: data.filteredSegments,
    capRounded: true,
    miterLimit: 8,
    opacity: 0.5,
    widthMinPixels: 0.8,
    widthMaxPixels: 4,
    getPath: (d: TFilteredSegment) => d.coords,
    getColor: getColor,
    getWidth: 10,
    pickable: true,
    autoHighlight: true,
    highlightColor: (d: any) => (d.object.routeCount) === 0 ? [160, 200, 255, 128] : [255, 255, 255, 128],
    updateTriggers: {
      getColor: [data.filteredSegments, filters, settings],
      getRadius: [data.filteredSegments, filters, settings],
    }
  })
};