import { Slider } from 'antd';

export default function HourSelector({ selectedHours, onChange, dispatcher }) {

  return (
    <Slider
      range={{ draggableTrack: true }}
      defaultValue={selectedHours}
      min={0}
      max={24}
      dots={false}
      tipFormatter={value => `${value}:00`}
      onAfterChange={onChange}
    />
  )
}