import './App.css';
import 'antd/dist/antd.css';
import Select from 'components/Select';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { CityData } from "CityData/CityData"
import MainNavigation from 'components/MainNavigation';
import StatisticView from 'views/StatisticView/StatisticView';
import MapView from 'views/MapView/MapView';
import { cityNames } from 'CityData/settings'

/* Probleme:
 * - Router zum Laufen bringen (Transitions in Map funktionieren mit Router nicht mehr)
 * - Seitendarstellung optimieren
 * 
*/
const cityData = new CityData();
const navItems = [
  { 'key': '/statistics', 'title': 'Statistiken' }
];

export default function BikePlusAnalytics() {
  const [cityId, setCityId] = useState('WI');
  const [mapMode, setMapMode] = useState('density');

  // hack for 
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  let navigate = useNavigate();

  // console.log('App', useLocation().pathname);

  // ggf. useCallback verwenden:
  useEffect(() => {
    cityData.onChange = (t) => {
      forceUpdate();
    }
  }, [forceUpdate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await cityData.loadCity(cityId);
      } catch (error) {
        console.log('Fetch error: ', error);
      }
    };
    fetchData();
  }, [cityId])

  const onChangeCity = useCallback((id) => {
    setCityId(id);
  }, []);

  function changeNav(e) {
    console.log("Navigate", e);
    if (e === '/density')
      setMapMode('density');
    else if (e === '/speed')
      setMapMode('speed');
    navigate(e);
  }

  const location = useLocation().pathname;

  return (
    <div id="appcontainer" >
      <div className="navbar">
        <div className='navHead'><h2 className='navHead'>DB Rad+</h2><h3 className='navHead'>Analytics</h3></div>
        <MainNavigation
          navItems={navItems}
          selected={useLocation().pathname}
          onChange={changeNav} />
        <div style={{ position: 'absolute', bottom: '0px', margin: '0px', width: '100%' }}>
          <Select
            style={{ position: 'absolute', bottom: '0px' }}
            options={cityNames}
            value={cityId}
            onChange={onChangeCity}>
          </Select></div>
      </div>
      {location === '/density' || location === '/speed' ?
        <MapView
          mapMode={mapMode}
          cityData={cityData}
          cityId={cityId}
          onChangeCity={onChangeCity} /> : null}
      {useLocation().pathname === '/' ?
        <StatisticView
          cityData={cityData}
          cityId={cityId}
          onChangeCity={onChangeCity} /> : null}
    </div>
  );
}