import { useCallback, useMemo } from 'react';
import { PathLayer } from '@deck.gl/layers';
import { DataFilterExtension } from '@deck.gl/extensions';
import { ColorPalettes } from 'CityData/ColorPalettes'

/* ToDo:
- Colors for zero routes and highlight-colors: Selectable, hardcoded or computed
- ggf. Hintergrundlayer für Straßen, größenangepasst.
- getter Funktionen ggf. irgendwie cachen (s.u.) 
*/

export function DensityLayer({ data, filters, settings }) {

  //console.log("DensityLayer", filters);
  // precompute end memoize getter functions
  // makes problems, but now layer is rendered twice
  /*
  const getWidth = useMemo(() => {
    const maxRoutes = data.maxRoutesPerSegment;
    const lineWidth = settings.lineWidth;
    const lineScale = settings.lineScale;
    const minWidth = lineWidth * (1 - lineScale);
    const widthFactor = lineWidth * lineScale * 4;
    return (d => minWidth + widthFactor * (d.routeCount / maxRoutes))
  }, [filters, settings.lineWidth, settings.lineScale, data.maxRoutesPerSegment])

  const getColor = useMemo(() => {
    const colorPalette = ColorPalettes[settings.palette];
    const factor = data.maxRoutesPerSegment / settings.colorFactor;
    return (d => (d.routeCount === 0) ?
      [0, 64, 128, 64] :
      colorPalette.getRGBA(d.routeCount / factor))
  }, [filters, settings.colorFactor, data.maxRoutesPerSegment, settings.palette])
  */
  
  const maxRoutes = data.maxRoutesPerSegment;
  const lineWidth = settings.lineWidth;
  const lineScale = settings.lineScale;
  const widthFactor = lineWidth * lineScale;
  const getWidth = d => lineWidth + widthFactor * (d.routeCount / maxRoutes);

  const colorPalette = ColorPalettes[settings.palette];
  const factor = data.maxRoutesPerSegment / settings.colorFactor;
  const getColor = d => (d.routeCount === 0) ? [0, 32, 48, 255] : colorPalette.getRGBA(d.routeCount / factor);

  return new PathLayer({
    id: 'density',
    data: data.filteredSegments,
    pickable: true,
    capRounded: true,
    miterLimit: 8,
    opacity: 1,
    widthMinPixels: 0.8,
    widthMaxPixels: 10,
    autoHighlight: true,
    getPath: d => d.coords,
    getColor: getColor,
    getWidth: settings.lineScale > 0 ? getWidth : settings.lineWidth,
    highlightColor: d => (d.object.routeCount) === 0 ? [255, 255, 255, 200] : [255, 255, 255, 200],
    parameters: {
      depthTest: true
    },
    updateTriggers: {
      getColor: [data.filteredSegments, settings.palette, data.maxRoutesPerSegment, settings.colorFactor],
      getFilterValue: [data.filteredSegments, filters.minMaxRoutes, data.maxRoutesPerSegment],
      getWidth: [data.filteredSegments, data.maxRoutesPerSegment, settings.lineWidth, settings.lineScale],
    },
    extensions: [new DataFilterExtension({ filterSize: 1 })],
    getFilterValue: o => o.routeCount,
    filterRange: filters.minMaxRoutes,
  })
};


export function AllSegmentsLayer({ data, filters, settings }) {
  const offset = 8;
  const maxRoutes = data.maxRoutesPerSegment;
  const lineWidth = settings.lineWidth + offset;
  const lineScale = settings.lineScale;
  const widthFactor = lineWidth * lineScale;
  const getWidth = d => lineWidth + widthFactor * (d.routeCount / maxRoutes);

  return new PathLayer({
    id: 'segments',
    data: data.segments,
    capRounded: true,
    miterLimit: 8,
    opacity: 1,
    widthMinPixels: 0.8,
    widthMaxPixels: 16,
    getPath: d => d.coords,
    getColor: [128, 128, 128, 255],
    getWidth: settings.lineScale > 0 ? getWidth :lineWidth,
    //getWidth: lineWidth,
    updateTriggers: {
      getWidth: [data.maxRoutesPerSegment, settings.lineWidth, settings.lineScale],
    },
  })
};