
export let cityNames = [
  { value: 'ARB',label: 'Ahrensburg' },
  { value: 'BA', label: 'Bamberg' },
  { value: 'CO', label: 'Coburg' },
  { value: 'ER', label: 'Erlangen' },
  { value: 'FS', label: 'Freising' },
  { value: 'HH', label: 'Hamburg' },
  { value: 'LI', label: 'Lindau' },
  { value: 'RGB',label: 'Regensburg' },
  { value: 'RNG',label: 'Renningen' },
  { value: 'SW', label: 'Schweinfurt' },
  { value: 'UTH',label: 'Unterhaching' },
  { value: 'WI', label: 'Wiesbaden' },
  { value: 'WLH',label: 'Weilheim' },
];

export let mapStylesArray = [
  { label: 'Dark Matter', value: 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json' },
  { label: 'Dark Matter ohne Text', value: 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json' },
  { label: 'Positron', value: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json' },
  { label: 'Positron ohne Text', value: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json' },
  { label: 'Voyager', value: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json' },
  { label: 'Voyager ohne Text', value: 'https://basemaps.cartocdn.com/gl/voyager-nolabels-gl-style/style.json' },
  { label: 'Dark MapBox', value: 'mapbox://styles/mapbox/dark-v10' },
  { label: 'Dark MapTiler', value: 'https://api.maptiler.com/maps/47207ad7-966c-4c18-9333-1b1766e4c08a/style.json?key=CATs2HY37IBFeRrAMfsv' },
];


export let mapStyles = {
  'Dark Matter': 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json',
  'Dark Matter ohne Text': 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json',
  'Positron': 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
  'Positron ohne Text': 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
  'Voyager': 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json',
  'Voyager ohne Text': 'https://basemaps.cartocdn.com/gl/voyager-nolabels-gl-style/style.json',
  'Dark MapBox': 'mapbox://styles/mapbox/dark-v10',
  'Dark MapTiler': 'https://api.maptiler.com/maps/47207ad7-966c-4c18-9333-1b1766e4c08a/style.json?key=CATs2HY37IBFeRrAMfsv',
};