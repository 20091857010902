import React, { useState } from 'react';
import { Tree } from 'antd';
import { streetTypesDict } from 'CityData/osmStreetTypes'

const options = {
  'main': ['motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'unclassified'],
  'living': ['residential', 'living_street'],
  'services': ['service', 'service_parking_aisle', 'service_driveway'],
  'walk': ['pedestrian', 'track', 'footway', 'path', 'cycleway', 'steps']
}
const treeData = [];
for (const k in options) {
  treeData.push({
    title: streetTypesDict[k],
    key: k,
    children: options[k].map(i => { return { 'title': streetTypesDict[i], 'key': i } })
  })
}

export default function StreetTypeSelector({ streetTypes, onChange }) {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    onChange(checkedKeysValue);
  };

  return (
    <Tree ghost
      selectable={false}
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={streetTypes}
      treeData={treeData}
    />
  );
};