import React, { useMemo } from "react"
//import ReactECharts from 'echarts-for-react';

// Selective Import
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { TitleComponent, DatasetComponent, ToolboxComponent} from 'echarts/components';
echarts.use(
  [LineChart, CanvasRenderer, TitleComponent, ToolboxComponent, DatasetComponent]
);

// Data besser parametriesieren, ggf. data, x und y als prop
export default function SimpleTimeline({ data, width='100%', height='100%' }) {

  //console.log("Simpletimeline", data);
  
  const dataset = useMemo(() => {
    return (data ? {
      dimensions: [
        { name: 'datetime', type: 'time' },
        { name: 'count', type: 'int' },
      ],
      source: data
    } : null);
  }, [data])

  let w = width ? width : '100%';
  let h = height ? height : '100%';
  
  return (
    <ReactEChartsCore 
      echarts={echarts}
      style={{ height: h, width: w }}
      option={{
        grid: { left: "0", top: "0", bottom: "20", right: "0" },
        xAxis: {
          type: 'time',
          axisLabel: { fontSize: 8, inside: false },
        },
        yAxis: {
          show: true,
          type: 'value',
          axisLabel: {
            formatter: (value) => value
          }
        },
        dataset: dataset,
        series: [{
          type: 'line', showSymbol: false,
          lineStyle: { width: 4, opacity: 0.2 },
          emphasis: { lineStyle: { width: 4, opacity: 0.9 } },
          areaStyle: { opacity: 0.0 },
          animation: true
        }],
      }}
    />
  )
}