import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
var moment = require('moment');

export default function DateRangeSelector({ dateRange, totalDateRange, onChange }) {

  function changeDateRange(m) {
    if (m && Array.isArray(m) && m.length === 2 && m[0] && m[1])
      onChange([m[0].toDate(), m[1].toDate()]);
  }

  function disabledDates(d) {
    if (totalDateRange && totalDateRange.length > 1)
      return !d || d.isAfter(totalDateRange[1]) || d.isSameOrBefore(totalDateRange[0])
    else
      return null
  }

  return (
    <RangePicker
      format='DD.MM.YY'
      size='small'
      bordered={true}
      allowClear={false}
      disabledDate={disabledDates}
      ranges={totalDateRange ? {
        'Gesamtzeitraum': [moment(totalDateRange[0]), moment(totalDateRange[1])]
      } : null}
      value={[moment(dateRange[0]), moment(dateRange[1])]}
      onCalendarChange={changeDateRange}
    />
  )
}