import React, { useMemo } from "react"
//import ReactECharts from 'echarts-for-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent, TooltipComponent, TitleComponent, DatasetComponent, } from 'echarts/components';
echarts.use(
  [BarChart, CanvasRenderer, GridComponent]
);

export default function SpeedHistogram({
  data, colorPalette
}) {
  //console.log("SpeedHistogram", data);
  const values = data;

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={{
        title: { show: false },
        toolbox: { show: false },
        grid: { left: "48", top: "7", bottom: "18", right: "35" },
        xAxis: {
          type: 'category',
          name: "km/h",
          nameGap: 8,
          nameLocation: 'end',
          nameTextStyle: { verticalAlign: 'middle' },
          //data: axis,
          axisLabel: {
            interval: 'auto',
          },
          axisTick: {
            interval: 'auto'
          },
        },
        yAxis: {
          type: 'value',
          //max: maxValue,
        },
        series: {
          data: values,
          type: 'bar',
        },
      }}
      style={{ width: "100%", height: "120px" }}
    />
  )
}