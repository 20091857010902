import { Collapse } from 'antd';
import './MapPanel.css'
import React from 'react';
import StreetTypeSelector from './SelectStreetType';
import TimeFilter from './PanelTime';
import MapSettings from './PanelSettings';
import DensityPanel from './PanelDensity';
import SpeedPanel from './PanelSpeed';
const { Panel } = Collapse;

/* ToDo:
- ggf. Oben Space analog zu Navbar
- Unterscheidung Mode: Traffik, Speed ...
*/

export default function FilterPanel({
  data, mapMode,
  filter, filterDispatcher,
  settings, settingsDispatcher
}) {
  //console.log("FilterPanel", mapMode);

  return (
    <>
      <Collapse
        defaultActiveKey={['0']}
        bordered={false}
        accordion={false}
        ghost={true}
        expandIconPosition='right'>
        { mapMode === 'density' &&
        <Panel header={<b>Verehrsdaten</b>} key="0">
          <DensityPanel
            data={data}
            filter={filter} 
            filterDispatcher={filterDispatcher}
            settings={settings}
            settingsDispatcher={settingsDispatcher}
          />
        </Panel>
        }
        { mapMode === 'speed' &&
        <Panel header={<b>Geschwindigkeitsdaten</b>} key="0">
          <SpeedPanel
            data={data}
            filter={filter} 
            filterDispatcher={filterDispatcher}
            settings={settings}
            settingsDispatcher={settingsDispatcher}
          />
        </Panel>
        }
        <Panel header={<b>Zeit</b>} key="1">
          <TimeFilter
            filter={filter} 
            filterDispatcher={filterDispatcher} 
            totalDateRange={data.totalDateRange} />
        </Panel>
        <Panel header={<b>Straßentyp</b>} key="2">
          <StreetTypeSelector
            streetTypes={filter.streetTypes}
            onChange={(v) => filterDispatcher({ type: 'setStreetTypes', value: v })}
          />
        </Panel>
        <Panel header={<b>Kartendarstellung</b>} key="3">
          <MapSettings
            settings={settings}
            settingsDispatcher={settingsDispatcher} />
        </Panel>
      </Collapse>
    </>
  )
}