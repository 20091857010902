import { useReducer } from 'react';
import { mapStylesArray } from 'CityData/settings'
import { ColorPaletteLabels } from 'CityData/ColorPalettes'

const initialSettings = {
  baseMap: mapStylesArray[1].value,
  timelineMode: 'weekByHours', // ? ggf. useState in MapView
  palette: ColorPaletteLabels[0].label, // versch. für trafficLines, speedLines, speedNodes
  colorFactor: 4, 
  lineWidth: 20, 
  lineScale: 0,  

  nodeSpeedMode: "range_absolute",
  nodePalette: ColorPaletteLabels[1].label, 
  nodeSpeedColorFactor: 1, 
  nodeWidth: 8, 
  nodeScale: 3  
}

/* maybe represent hours as array:
  this.filters.hour = range(this.filters.startHour, this.filters.endHour);
*/

function settingsReducer(settings, action) {
  // console.log("settingsReducer", action.type, action.value);
  switch (action.type) {
    case 'setBaseMap':
      return { ...settings, baseMap: action.value };
    case 'setPalette':
      return { ...settings, palette: action.value };
    case 'setColorFactor':
      return { ...settings, colorFactor: action.value };
    case 'setLineWidth':
      return { ...settings, lineWidth: action.value };
    case 'setLineScale':
      return { ...settings, lineScale: action.value };

    case 'setNodeSpeedMode':
      return { ...settings, nodeSpeedMode: action.value };

    case 'setNodePalette':
      return { ...settings, nodePalette: action.value };
    case 'setNodeSpeedColorFactor':
      return { ...settings, nodeSpeedColorFactor: action.value };
    case 'setNodeWidth':
      return { ...settings, nodeWidth: action.value };
    case 'setNodeScale':
      return { ...settings, nodeScale: action.value };
    default:
      throw new Error();
  }
}

function filterReducer(filter, action) {
  //console.log("DispatchFilter", action.type, action.value);
  switch (action.type) {
    case 'setHourRange':
      return { ...filter, hourRange: action.value };
    case 'setSelectedDays':
      return { ...filter, selectedDays: action.value };
    case 'setMinMaxRoutes':
      return { ...filter, minMaxRoutes: action.value };
    case 'setDateRange':
      return { ...filter, dateRange: action.value };
    case 'setStreetTypes':
      return { ...filter, streetTypes: action.value };
    case 'setNodeSpeedRange':
      return { ...filter, nodeSpeedRange: action.value, segmentSpeedRange: action.value };
    case 'setSegmentSpeedRange':
      return { ...filter, segmentSpeedRange: action.value };
    case 'setNodeSpeedPercentile':
      return { ...filter, nodeSpeedPercentile: action.value };
    case 'setSegmentSpeedPercentile':
      return { ...filter, segmentSpeedPercentile: action.value };
    default:
      throw new Error();
  }
}

export function useMapFilter(cityData) {
  return useReducer(filterReducer, cityData.filters);
}

export function useMapSettings() {
  return useReducer(settingsReducer, initialSettings);
}