import React from 'react';
import { Menu } from 'antd';
import { FaMap, FaChartLine } from 'react-icons/fa';
import './MainNavigation.css';

export default function MainNavigation({ selected, onChange }) {

  function onSelect(e) {
    onChange(e.key);
  }
  return (
    <>
      <Menu
        onSelect={onSelect}
        selectedKeys={[selected]}
        mode="inline"
        theme='dark'
      >
        <Menu.Item key="/" icon={<FaChartLine />}>Statistiken</Menu.Item>
        <Menu.Item key="/density" icon={<FaMap />}>Verkehrsdichte</Menu.Item>
        <Menu.Item key="/speed" icon={<FaMap />}>Geschwindigkeit</Menu.Item>
      </Menu>
    </>
  )
}