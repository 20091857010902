// Helper for filling ranges
export function hoursFromRange(start, stop) {
  return Array(Math.ceil(stop - start)).fill(start).map((x, y) => x + y);
}

export function clamp(value, limit1, limit2) {
  return Math.max(
    Math.min(limit1, limit2),
    Math.min(value, Math.max(limit1, limit2)));
}

export function mapClamp(value, inMin, inMax, outMin, outMax) {
  return clamp(mapValue(value, inMin, inMax, outMin, outMax), outMin, outMax);
}

export function mapValue(value, inMin, inMax, outMin, outMax) {
  return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

/* Speed-Histogram Calculations
count
    4 |       *
    3 |     * *   * *
    2 |   * * * * * * *
    1 | * * * * * * * * * *
       ________________________
        0 1 2 3 4 5 6 7 8 9
             Speed (km/h)
  */

function countEntries(speeds) {
  return speeds.reduce((a, b) => a + b);
}

export function speedMean(speeds) {
  if (speeds.length === 0) return -1;
  let numEntries = countEntries(speeds);
  return speeds.map((v, i) => (i + 0.5) * v).reduce((a, b) => a + b) / numEntries;
}

export function speedMedian(speeds, percentile) {
  if (speeds.length === 0) return -1;
  let numEntries = countEntries(speeds);
  let thresh = numEntries * percentile
  for (let speed = 0, cumSum = 0; speed < speeds.length; speed++) {
    cumSum += speeds[speed];
    if (cumSum >= thresh)
      return speed;
  }
}

export function speedMode(speeds) {
  if (speeds.length === 0) return -1;
  for (var i = 1, max = speeds[0], mode = 0; i < speeds.length; i++) {
    if (speeds[i] > max) {
      mode = i;
      max = speeds[i];
    }
  }
  return mode;
}

export function speedPercentInRange(speeds, min, max) {
  if (speeds.length === 0) return -1;
  let numEntries = countEntries(speeds);
  return speeds.slice(min, max).reduce((a, b) => a + b, 0) / numEntries
  let count = 0;
  for (var i = min; i <= max; i++) {
    count += speeds[i];
  }
  return count / numEntries;
}

/*
 Array Vector Operations
*/

export function addArray(first, second) {
  if (!Array.isArray(first)) {
    if (Array.isArray(second)) {
      return second;
    } else {
      return null;
    }
  }
  if (Array.isArray(second) && (first.length >= second.length)) {
    let result = [...first];
    //let result = first.slice(0);
    let len = first.length;
    let i = 0;
    for (i = 0; i < len; i++) {
      result[i] += second[i];
    }
    return result;
  }
}
export function addArrayFast(first, second) {
  let result = [...first];
  let len = result.length;
  for (let i = 0; i < len; i++) {
    result[i] += second[i];
  }
  return result;
}

export function divArray(first, scalar) {
  if (Array.isArray(first)) {
    let len = first.length;
    let i = 0;
    for (i = 0; i < len; i++) {
      first[i] /= scalar;
    }
  }
  return first;
}

export function incMap(map, key, value) {
  if (map.has(key))
    map.set(key, map.get(key) + value);
  else
    map.set(key, value);
};