import DaySelector from './SelectDay';
import DateRangeSelector from './SelectDateRange';
import HourSelector from './SelectHour';
import Divider from 'components/Divider';

export default function TimeFilter({filter, filterDispatcher, totalDateRange}) {

  return (
    <>
      <Divider>Zeitraum</Divider>
      <DateRangeSelector
        dateRange={filter.dateRange}
        totalDateRange={totalDateRange}
        onChange={(v) => filterDispatcher({ type: 'setDateRange', value: v })}
      />
      <Divider>Wochentag</Divider>
      <DaySelector
        selectedDays={filter.selectedDays}
        onChange={(v) => filterDispatcher({ type: 'setSelectedDays', value: v })}
      />
      <Divider>Tageszeit</Divider>
      <HourSelector
        selectedHours={filter.hourRange}
        onChange={(v) => filterDispatcher({ type: 'setHourRange', value: v })}
      />
      <Divider />
    </>
  )
}
