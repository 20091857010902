import React, { useMemo } from 'react';
import { Select } from 'antd';
import { ColorPalettes } from 'CityData/ColorPalettes';

/* ToDo:
- React-Warning, dass kein eindeutiger Key vorhanden sei
- 
*/

function renderPalette(name) {
  let palette = ColorPalettes[name];
  const steps = 10;
  let bars = []
  let barWidth = '' + (100 / steps) + '%';
  for (let i = 0; i <= steps; i++) {
    let bgColor = palette.getHex(i / steps);
    bars.push(<span style={{ display: 'inline-block', width: barWidth, height: '12px', backgroundColor: bgColor }}></span>)
  }
  return <div style={{ margin:'0', padding:'0', width:'90%' }}>{bars}</div>
}

export default function SelectPalette({ palette, onChange }) {

  const paletteOptions = useMemo(() => {
    return Object.keys(ColorPalettes).map((x) => {
      return ({ 'label': renderPalette(x), 'key': x, 'value': x });
    });
  }, []);

    return (
      <Select
        options={paletteOptions}
        value={palette}
        onChange={onChange}
        style={{ width: '100%' }}>
      </Select>
    )
  }