import React, { useState, useEffect, useCallback } from 'react';
import { StaticMap } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { FlyToInterpolator } from 'deck.gl';
import { getTooltip } from './MapTooltip';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibWFuZnJlZGtyYWZ0IiwiYSI6ImNraXZqcGMxeTBrY2ozMG1lNHB6MWxqOHgifQ.E_VXK8mulfZSeMp1zkTO3w';
const maxZoom = 18;

export default function Map({ baseMap, layers, mapView }) {
  //console.log("Map");

  const [initialView, setInitialView] = useState({
    latitude: mapView.lat, longitude: mapView.lon, zoom: mapView.zoom,
    pitch: 0, bearing: 0, maxZoom: maxZoom,
  })

  const onViewStateChange = useCallback(
    ({ viewState: vs }) => {
      console.log(`${vs.longitude} ${vs.latitude} ${vs.zoom}`);

    }, []
  );

  useEffect(() => {
    setInitialView({
      longitude: mapView.lon, latitude: mapView.lat, zoom: mapView.zoom,
      pitch: 0, bearing: 0, maxZoom: maxZoom,
      transitionDuration: 5000, transitionInterpolator: new FlyToInterpolator()
    })
  }, [mapView])

  return (
    <>
      <DeckGL width="100%" height="100%"
        style={{ position: 'relative', width: '100%', sheight: '100%' }}
        layers={layers}
        initialViewState={initialView}
        //onViewStateChange={onViewStateChange}
        controller={{ dragMode: 'pan' }}
        //useDevicePixels={false}
        getTooltip={getTooltip}
      >
        <StaticMap
          reuseMaps
          mapStyle={baseMap}
          preventStyleDiffing={true}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          disableTokenWarning={false}
          attributionControl={false}
        />
      </DeckGL>
    </>
  );
}