import { Slider as AntSlider } from 'antd'

export default function Slider({ min, max, step=1, value, onChange }) {

  return (
    <AntSlider
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
    />
  );
}