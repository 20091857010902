
import { streetTypesDict } from 'CityData/osmStreetTypes';

export function getTooltip({ object }) {
  //console.log(object)
  let result, text = null;
  if (object) {
    result = true;
    if (object.routeCount)
      result = object.routeCount;
    text = '';
    if (object.segmentId) {
      if (object.name)
        text += `<b>${object.name}</b><br>`
      else
        text += `<b>Unbenannter Weg</b><br>`
      if (streetTypesDict[object.type])
        text += `${streetTypesDict[object.type]}`
      if (object.access)
        text += ` <i>(${object.access})</i>`
    } else {
      text += `<b>Kreuzung</b>`
    }
    if (object.routeCount)
      text += `<br>${object.routeCount} Routen`
    //if (object.avrSpeed)
    text += `<br>${Math.round(object.avrSpeed)} km/h`
    if (object.nodeId && object.speedRangeCount)
      text += `<br>${object.speedRangeCount} von ${object.speedsCount} Routen im Bereich`
  }
  return (
    result && {
      html: `<div>${text}</div><div></div>`
    }
  );
}