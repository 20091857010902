import chroma from 'chroma-js';
import { clamp } from 'CityData/utilities.js'

export class ColorPalette {
  constructor(chromaScale) {
    this.scale = chromaScale;
  }

  getRGB(value) {
    value = clamp(value, 0, 1);
    return this.scale(value).rgb();
  }
  
  getRGBA(value) {
    let result = this.scale(clamp(value, 0, 1)).rgba();
    result[3] = Math.round(result[3] * 255);
    return result;
  }

  getHex(value) {
    value = clamp(value, 0, 1);
    return this.scale(value).hex();
  }

  getColors(num) {
    return this.scale.colors(num);
  }
}

export const ColorPalettes = {
  'Grün-Gelb-Rot': new ColorPalette(chroma.scale(['#004000', '#00ff00', '#ffff00', '#ff0000']).mode('lrgb')),
  'Rot-Gelb-Grün': new ColorPalette(chroma.scale(['#ff0000', '#f0f000', '#00ff00']).mode('lrgb')),
  'Rot-Grün': new ColorPalette(chroma.scale(['#ff0000', '#00ff00']).mode('lrgb')),
  'Blau-Rot-Orange': new ColorPalette(chroma.scale(['#003060', '#ff0060', '#ff2000', '#ffB000'])),
  'Blau-Rot-Gelb': new ColorPalette(chroma.scale(['#400090', '#e000a0', '#ff4000', '#ffff00'])),
  'Schwarz Weiß': new ColorPalette(chroma.scale(['#101010', '#a0a0a0', '#ffffff'])),
  'Schwarz Weiß Alpha': new ColorPalette(chroma.scale(['#10101000', '#a0a0a0b0', '#ffffffff'])),
  'Test': new ColorPalette(chroma.scale(['#ff0000', '#00ff00']).mode('lrgb')),
}

export const ColorPaletteLabels = Object.keys(ColorPalettes).map((x) => { return { 'label': x, 'value': x } })
//export const ColorPaletteLabels = Object.keys(ColorPalettes)

export default class ColorRange {
  palettes = {}
  constructor() {
    this.scale = chroma.scale(['#00ff00', '#ff0000']).mode('lab').correctLightness();
  }


  getRGB(value) {
    this.scale(value).rgb();
  }

  getColors(num) {
    this.scale.colors(num);
  }

  getColorArray2(value) {
    return this.scale(value).rgb();
  }
}


