import { Slider as AntSlider } from 'antd';
import Divider from 'components/Divider';
import Slider from "components/Slider";
import SelectPalette from './SelectPalette';

/* ToDO:
- Weitere sinnvolle Parameter
- Slider ggf. Werte * 10
*/

export default function DensityPanel({ data, filter, filterDispatcher, settings, settingsDispatcher }) {
  return (
    <>
      <Divider orientation="left" orientationMargin="0" plain>
        Min/Max Routen
      </Divider>
      <AntSlider
        range={{ draggableTrack: true }}
        value={filter.minMaxRoutes}
        min={0}
        max={data.maxRoutesPerSegment}
        dots={false}
        onChange={(x) => filterDispatcher({ type: 'setMinMaxRoutes', value: x })}
        onAfterChange={(x) => filterDispatcher({ type: 'setMinMaxRoutes', value: x })}
      />

      <Divider>Farbspreizung</Divider>
      <Slider min={0} max={4} step={0.1} value={settings.colorFactor}
        onChange={(x) => settingsDispatcher({ type: 'setColorFactor', value: x })} />

      <Divider>Palette</Divider>
      <SelectPalette
        palette={settings.palette}
        onChange={(x) => settingsDispatcher({ type: 'setPalette', value: x })} />

      <Divider>Linien Breite</Divider>
      <Slider min={2} max={30} step={1} value={settings.lineWidth}
        onChange={(x) => settingsDispatcher({ type: 'setLineWidth', value: x })} />
        
      <Divider>Linien Skalierung</Divider>
      <Slider min={0} max={8} step={0.1} value={settings.lineScale}
        onChange={(x) => settingsDispatcher({ type: 'setLineScale', value: x })} />
    </>
  )
}