import { Statistic } from 'antd';
import { BsArrowUpRight, BsArrowDownRight } from 'react-icons/bs';

export default function StatisticItem({ title, value, trend=null, suffix='' }) {

  const color = trend ===  'up' ? '#3f8600' : trend === 'down' ? '#cf1322' : null;
  const style = color ? { color: color } : {};
  const prefix = trend ===  'up' ? <BsArrowUpRight /> : trend === 'down' ? <BsArrowDownRight /> : null;

  return (
    <Statistic 
    title={title} 
    value={value} 
    decimalSeparator=',' 
    groupSeparator="." 
    valueStyle={style}
    prefix={prefix}
    suffix={suffix}/>
  )
}