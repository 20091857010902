import './StatisticView.css';
import React from 'react';
import { Card, Col, Row, Space } from 'antd';
import SimpleTimeline from './SimpleTimeline'
import StatisticItem from './StatisticItem';
import TripMap from './TripMap';
import dayjs from 'dayjs';
import { CityStatistics } from 'CityData/CityStatistics';


/*
  - Einzelne Kartentypen auslagern
  - Flip Card (falls gewollt) auslagern
  - Höhe derzeit durch .ant-card-body definiert
  - Cards/Boxen responsive float
  - 
*/
function clickCard(e) {
  console.log(e);
}

export default function StatisticView({ onChange, cityData }) {

  const stats: CityStatistics = cityData.statistics;
  console.log('StatisticView', stats);

  const gridStyle = {
    width: '50%',
    boxShadow: 'none'
  };

  // Trend Berechnungen ggf. in CityStatistics
  let yesterday = null;
  if (stats.loaded) {
    let lastTwoDays = stats.byDay.slice(-2)
    const trends = ['down', 'eq', 'up'];
    let lengthDelta = lastTwoDays[1].length - lastTwoDays[0].length;
    let countDelta = lastTwoDays[1].count - lastTwoDays[0].count;
    let lengthTrend = trends[Math.sign(lengthDelta) + 1];
    let countTrend = trends[Math.sign(countDelta) + 1];
    yesterday = {
      length: lastTwoDays[1].length,
      count: lastTwoDays[1].count,
      lengthTrend: lengthTrend,
      countTrend: countTrend,
    }
  }

  return (
    <div id="statisticsview">
      <Row gutter={[16, 16]} >
        <Col span={16}>
          <Row gutter={[0, 16]} >
            <Col span={24}>
              <Card title="Letzte Woche" bordered={true} hoverable onClick={clickCard} id='mapCard'>
                <TripMap
                  routes={stats?.latestRoutes}
                  mapView={cityData.mapView}></TripMap>
              </Card>
            </Col>
            <Col span={24}>
              <Card bordered={true} hoverable onClick={clickCard} title="Letzte 30 Tage" id='timeLine'>
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Routen" value={stats.lastThirtyDays?.count} />
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Kilometer" value={stats.lastThirtyDays?.length} />
                </Card.Grid>
                <SimpleTimeline
                  data={stats?.byDay}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[0, 16]} >
            <Col span={24}>
              <Card bordered={true} hoverable onClick={clickCard} title="Gestern">
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Routen" value={yesterday?.count} trend={yesterday?.countTrend} />
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Kilometer" value={yesterday?.length} trend={yesterday?.lengthTrend} />
                </Card.Grid>
              </Card>
            </Col>
            <Col span={24}>
              <Card bordered={true} hoverable onClick={clickCard} title="Letzte Woche">
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Routen" value={stats.lastSevenDays?.count} />
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Kilometer" value={stats.lastSevenDays?.length} />
                </Card.Grid>
              </Card>
            </Col>
            <Col span={24}>
              <Card bordered={true} hoverable onClick={clickCard}
                title={'Seit ' + dayjs(stats.totalStats?.startDate).format('DD.MM.YYYY')}>
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Routen" value={stats.totalStats?.count} />
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                  <StatisticItem title="Kilometer" value={stats.totalStats?.length} />
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
  
}