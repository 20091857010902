
export let streetTypesArrayxx = [
  'motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'unclassified', 'residential', 'living_street',
  'service', 'service_parking_aisle', 'service_driveway',
  'pedestrian', 'track', 'footway', 'path', 'cycleway'];

export let streetTypesDict = {
  'motorway': 'Autobahn',
  'trunk': 'Autostraße',
  'primary': 'Bundesstraße',
  'secondary': 'Landesstraße',
  'tertiary': 'Kreisstraße',
  'unclassified': 'Nebenstraße',
  'residential': 'Wohnstraße',
  'living_street': ' Spielstraße',
  'service': 'Erschließungsweg',
  'service_parking_aisle': 'Parkplatz',
  'service_driveway': 'Zufahrt',
  'pedestrian': 'Fußgängerweg',
  'track': 'Feldweg',
  'footway': 'Gehweg',
  'path': 'Pfad',
  'cycleway': 'Radweg',
  'steps': 'Stufen',
  // Oberkategorien
  'main': 'Hauptstraßen',
  'living': 'Wohnstraßen',
  'services': 'Erschließungswege',
  'walk': 'Fußgänger & Radwege'
}

export let streetTypesArray = Object.keys(streetTypesDict);

export let streetTypes = Object.keys(streetTypesDict).map(function(key) {
  return { 'label': streetTypesDict[key], 'value': key };
});