import React, { useState, useEffect, useRef } from 'react';
import { StaticMap } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { FlyToInterpolator } from 'deck.gl';
import { TripsLayer } from '@deck.gl/geo-layers';
import dayjs from 'dayjs';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibWFuZnJlZGtyYWZ0IiwiYSI6ImNraXZqcGMxeTBrY2ozMG1lNHB6MWxqOHgifQ.E_VXK8mulfZSeMp1zkTO3w';

function formatDate(routes, time) {
  if (routes.loaded) {
    const d = new Date(1000 * time + 1000 * routes.start);
    return dayjs(d).format('DD.MM.YYYY HH:mm');
  }
}

export default function TripMap({
  routes, mapView
}) {

  const [initialView, setInitialView] = useState({
    latitude: mapView.lat, longitude: mapView.lon, zoom: mapView.zoom
  })
  useEffect(() => {
    setInitialView({
      longitude: mapView.lon, latitude: mapView.lat, zoom: mapView.zoom,
      transitionDuration: 1000, transitionInterpolator: new FlyToInterpolator()
    })
  }, [mapView])

  const [time, setTime] = useState(0);
  const requestRef = useRef();

  const animate = (t) => {
    if (routes.loaded) {
      setTime(t => (t + 200) % routes.duration);
      requestRef.current = window.requestAnimationFrame(animate);
    }
  };

  useEffect(() => {
      requestRef.current = window.requestAnimationFrame(animate);
      return () => window.cancelAnimationFrame(requestRef.current);
    },  [routes]
  );


  const tripsLayer = new TripsLayer({
    id: 'TripsLayer',
    data: routes.points,
    getPath: d => d.coords,
    getTimestamps: d => d.time,
    currentTime: time,
    trailLength: 80000,
    getColor: [255, 128, 0],
    widthMinPixels: 1.6,
    opacity: 0.3,
  });

  return (
    <><h3
      style={{ position: 'absolute', padding: '10px', backgroundColor: 'black', color: 'white', zIndex: 10 }}
    >{formatDate(routes, time)}</h3>
      <DeckGL width="100%" height="100%"
        style={{ position: 'relative', width: '100%', sheight: '100%' }}
        layers={[tripsLayer]}
        initialViewState={initialView}
        controller={{ dragMode: 'pan' }}
      >
        <StaticMap
          reuseMaps
          mapStyle='https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json'
          preventStyleDiffing={true}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          disableTokenWarning={false}
          attributionControl={false}
        />
      </DeckGL>
    </>
  );
}