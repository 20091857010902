import { TStatsItem, TStatsItemByDay, TStatsItemTotals, TRouteList } from "./CityDataTypes";
import { JSONStats, JSONByDay, JSONRoutes, JSONTraffic, JSONSegment } from 'CityData/JSONTypes'
import { TMapView } from "./CityDataTypes";


export class CityStatistics {

  loaded: boolean = false;
  mapView: TMapView
  byDay: Array<TStatsItemByDay> = null;
  lastSevenDays: TStatsItem = null;
  lastThirtyDays: TStatsItem = null;
  totalStats: TStatsItemTotals = null;
  latestRoutes: TRouteList = {
    points: null,
    start: 0,
    duration: 0,
    loaded: false
  };

  async fetchStatistics(cityId) {
    const url = `data/statistics_${cityId}.json`;
    const response = await fetch(url);
    if (!response.ok) {
      const message = `An http-error has occured: ${response.status}`;
      throw new Error(message);
    }
    const stats: JSONStats = await response.json();
    // console.log(stats)
    this.prepareStats(stats);
    return true;
  }

  prepareStats(stats: JSONStats) {
    // console.log('PrepareStats', stats);
    console.time('PrepareStats');
    this.mapView = stats['map_view'];
    // calculate days totals
    const reducer = (p: any, c: any): any => {
      return {
        length: c.length + p.length,
        count: c.count + p.count
      }
    };
    this.byDay = stats.by_day;
    this.lastSevenDays = stats.by_day.slice(-7).reduce(reducer);
    this.lastThirtyDays = stats.by_day.slice(-30).reduce(reducer);
    this.loaded = true;
    this.totalStats = {
      startDate: new Date(stats.total_stats.start_date),
      length: stats.total_stats.length,
      count: stats.total_stats.count,
    }
    console.timeEnd('PrepareStats');
  }

  /*
  Load latest routes
  */
  async fetchRoutes(cityId) {
    console.time("fetchRoutes");
    const url = `data/routes_${cityId}.json`;
    const response = await fetch(url);
    if (!response.ok) {
      const message = `An http-error has occured: ${response.status}`;
      throw new Error(message);
    }
    const routes: JSONRoutes = await response.json();
    this.prepareRoutes(routes);
    console.timeEnd("fetchRoutes");
    return true;
  }

  prepareRoutes(routes: JSONRoutes) {
    this.latestRoutes = {
      points: routes.route_list,
      start: routes.route_start,
      duration: routes.route_duration,
      loaded: true
    }
  }
}