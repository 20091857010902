import { useMemo } from 'react';
import Map from './Map';
import { DensityLayer, AllSegmentsLayer } from './LayerDensity';
import { SpeedDotLayer, SpeedLineLayer } from './LayerSpeed';

export default function TrafficMap({ data, settings, filters, mapMode }) {
  // console.log("TrafficMap");

  const layerList = useMemo(() => {
    if (mapMode === 'density')
      //return [AllSegmentsLayer, DensityLayer];
      return [DensityLayer];
    else if (mapMode === 'speed')
      return [SpeedLineLayer, SpeedDotLayer];
  }, [mapMode, settings]);

  const layers = [];
  if (data.isLoaded) {
    for (let layer of layerList) {
      let newLayer = layer({ data: data, filters: filters, settings: settings });
      layers.push(newLayer);
    }
  };

  return (
    <Map
      mapView={data.mapView}
      baseMap={settings.baseMap}
      layers={layers}
    />)
}