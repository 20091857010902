import React, { useState } from 'react';
import TimeGraph from './TimeGraph';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default function TimeLine({ data, palette }) {
  const [timelineMode, setTimelineMode] = useState('weekByHours')

  return (
    <>
      <Tabs
        activeKey={timelineMode}
        size='small'
        onChange={setTimelineMode}>
        <TabPane tab="Woche nach Stunden" key="weekByHours"></TabPane>
        <TabPane tab="Woche nach Tagen" key="weekByDays"></TabPane>
        <TabPane tab="Tag nach Stunden" key="dayByHours"></TabPane>
        <TabPane tab="Gesamtzeitraum" key="all"></TabPane>
      </Tabs>
      <TimeGraph
        data={data}
        colorPalette={palette}
        mode={timelineMode}
      />
    </>
  );
}