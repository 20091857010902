import React, { useState } from 'react';
import { Tree } from 'antd';

const treeData = [
  {
    title: 'Arbeitswoche',
    key: 'work',
    children: [
      { title: 'Montag', key: 1 },
      { title: 'Dienstag', key: 2 },
      { title: 'Mittwoch', key: 3 },
      { title: 'Donnerstag', key: 4 },
      { title: 'Freitag', key: 5 },
    ]
  }, {
    title: 'Wochenende',
    key: 'weekend',
    children: [
      { title: 'Samstag', key: 6 },
      { title: 'Sonntag', key: 0 },
    ]
  },
];

export default function StreetTypeSelector({selectedDays, onChange}) {

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    onChange(checkedKeysValue);
  };

  return (
    <Tree ghost
      selectable={false}
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={selectedDays}
      treeData={treeData}
    />
  );
};