import { Collapse, Slider as AntSlider } from 'antd';
import Divider from 'components/Divider';
import Select from 'components/Select';
import Slider from "components/Slider";
import SelectPalette from './SelectPalette';


export default function SpeedPanel({ data, filter, filterDispatcher, settings, settingsDispatcher }) {
  //console.log("SpeedPanel", filter)
  return (
    <>
      <Divider orientation="left" orientationMargin="0" plain>
        Geschwindigkeits Metrik
      </Divider>
      <Select
        options={[{ label: 'Bereich absolut', value: 'range_absolute' }, { label: 'Bereich relativ', value: 'range_relative' }, { label: 'Quantil', value: 'percentile' }]}
        value={settings.nodeSpeedMode}
        onChange={(x) => settingsDispatcher({ type: 'setNodeSpeedMode', value: x })}>
      </Select>
      {settings.nodeSpeedMode.startsWith('range') ?
        <>
          <Divider orientation="left" orientationMargin="0" plain>
            Bereich ({filter.nodeSpeedRange[0]}-{filter.nodeSpeedRange[1]} km/h)
          </Divider>
          <AntSlider
            range={{ draggableTrack: true }}
            defaultValue={filter.nodeSpeedRange}
            min={0}
            max={30}
            dots={false}
            onAfterChange={(x) => filterDispatcher({ type: 'setNodeSpeedRange', value: x })}
          />
        </> : null}

      {settings.nodeSpeedMode === 'percentile' ?
        <>
          <Divider>Node Percentile</Divider>
          <Slider min={0} max={1} step={0.1} value={filter.nodeSpeedPercentile}
            onChange={(x) => filterDispatcher({ type: 'setNodeSpeedPercentile', value: x })} />
        </> : null}
      <Collapse
        defaultActiveKey={['0']}
        bordered={false}
        accordion={false}
        ghost={true}
        expandIconPosition='right'>
        <Collapse.Panel header="Feintuning" key="2">
          <Divider>Farbspreizung</Divider>
          <Slider min={0} max={4} step={0.1} value={settings.nodeSpeedColorFactor}
            onChange={(x) => settingsDispatcher({ type: 'setNodeSpeedColorFactor', value: x })} />

          <Divider>Palette</Divider>
          <SelectPalette
            palette={settings.nodePalette}
            onChange={(x) => settingsDispatcher({ type: 'setNodePalette', value: x })} />

          <Divider>Breite</Divider>
          <Slider min={0} max={100} step={1} value={settings.nodeWidth}
            onChange={(x) => settingsDispatcher({ type: 'setNodeWidth', value: x })} />

          <Divider>Skalierung</Divider>
          <Slider min={0} max={8} step={0.1} value={settings.nodeScale}
            onChange={(x) => settingsDispatcher({ type: 'setNodeScale', value: x })} />
          <Divider orientation="left" orientationMargin="0" plain>
            Geschwindigkeitsbereich für Straßen
          </Divider>
          <AntSlider
            range={{ draggableTrack: true }}
            defaultValue={filter.segmentSpeedRange}
            min={0}
            max={30}
            dots={false}
            onAfterChange={(x) => filterDispatcher({ type: 'setSegmentSpeedRange', value: x })}
          />


        </Collapse.Panel>
      </Collapse>

    </>
  )
}