import './Divider.css';

export default function Divider({ children }) {
  return (
    <>
    <hr className='divider'/>
    {children ? 
    <div className='divider'>{children}</div>
    : null}
    </>
  )
}