import Divider from "components/Divider";
import Select from "components/Select";
import { mapStylesArray } from 'CityData/settings'

export default function MapSettings({ settings, settingsDispatcher }) {

  return (
    <>
      <Divider>Basis-Karte</Divider>
      <Select
        options={mapStylesArray}
        value={settings.baseMap}
        onChange={(x) => settingsDispatcher({ type: 'setBaseMap', value: x })}>
      </Select>

    </>
  );
}