import { streetTypes, streetTypesDict } from 'CityData/osmStreetTypes';
import { addArray, divArray, addArrayFast, incMap } from 'CityData/utilities';
import { speedMean, speedMode, speedMedian, speedPercentInRange } from 'CityData/utilities'
import { JSONStats, JSONByDay, JSONRoutes, JSONTraffic, JSONSegment, JSONNode } from 'CityData/JSONTypes'
import { TFilteredNode, TFilteredNodes, TFilteredSegment, TFilteredSegments, TSegment } from 'CityData/CityDataTypes'
import { CityStatistics } from './CityStatistics';

/*
ToDo:
- Statistikberechnungen inkl. Trends
- Speed-Metriken ausdenken, parametrisieren und implementieren
- Filter gliedern in StreetType, Datetime und Speeds
- Datenstrukturen und Funktionen sauber benennen, typisieren und ausmisten
- ggf. Ladefunktionen sowie Traffic auslagern
- ggf. Histogrammberechnungen und -Konstanten auslagern
- ggf. Filterfunktionen auslagern
- Filterfunktionen optimieren und aufs notwendige reduzieren
*/

export class CityData {
  // City state
  cityId: string = null;
  mapView = { 'lat': 51.096623, 'lon': 10.008545, 'zoom': 5.3 };
  isLoaded: boolean = false;
  onChange = null;
  numSpeedBins = 30;
  // City Statistics
  statistics: CityStatistics = new CityStatistics();
  // Traffic Data
  segments: TFilteredSegments = null;    // array of loaded street-segments 
  filteredSegments: TFilteredSegments = null;
  nodes: TFilteredNodes = null;
  filteredNodes = null;
  segmentStats = null; // array of {datetime: count}
  nodeStats = null; // array of {datetime: count}
  // Timeline Data
  timeTable = null;   // key=segment_id, value = [routes]
  totalDateRange = null;   // array [startDate, endDate]
  streetTypeMap = null;
  // Filtering & Aggregation Summaries
  maxRoutesPerSegment = 0;
  totalSegmentSpeedCount = 0;
  maxSegmentSpeedCount = 0;
  totalNodeSpeedCount = 0;
  maxNodeSpeedCount = 0;
  totalSpeeds: Array<number> = [];

  filters = {
    streetTypes: streetTypes.map(i => i.value),
    dateRange: [],
    minMaxRoutes: [0, 10000],
    selectedDays: [0, 1, 2, 3, 4, 5, 6],
    hourRange: [0, 24],
    nodeSpeedRange: [0, 5],
    segmentSpeedRange: [0, 5],
    nodeSpeedPercentile: 0.5,
    segmentSpeedPercentile: 0.5
  }

  /* 
  * Load Data
  */
  async loadCity(cityId: string) {
    await this.statistics.fetchStatistics(cityId);
    this.mapView = this.statistics.mapView;
    if (this.onChange) this.onChange('statistics');
    await this.statistics.fetchRoutes(cityId);
    if (this.onChange) this.onChange('routes');
    this.fetchTraffic(cityId);
    this.cityId = cityId;
  }

  /*
  Fetch traffic data
  */
  async fetchTraffic(cityId: string) {
    const url = `data/traffic_${cityId}.json`;
    console.time("Traffic loaded");
    const response = await fetch(url);
    console.timeEnd("Traffic loaded");
    if (!response.ok) {
      const message = `An http-error has occured: ${response.status}`;
      throw new Error(message);
    }
    console.time("Traffic decoded");
    const traffic: JSONTraffic = await response.json();
    console.timeEnd("Traffic decoded");
    this.prepareTraffic(traffic);
    return true;
  }

  /*
  * Build Data Structures 
  */

  buildTimeTable(hourMap) {
    let timeTable = [];
    for (let item of hourMap) {
      let dt = new Date(item[0]);
      timeTable.push({
        dateTime: item[0],
        hourOfDay: dt.getHours(),
        hourOfWeek: dt.getDay() * 24 + dt.getHours(),
        dayOfWeek: dt.getDay(),
        count: item[1]
      })
    }
    return timeTable;
  }

  prepareTraffic(data: JSONTraffic) {
    // console.log('PrepareData', data);
    console.time('PrepareData');
    //this.segments = data['segments'];
    this.segments = data.segments.map((s: JSONSegment): TFilteredSegment => {
      return {
        segmentId: s.segment_id,
        osmid: s.osmid,
        name: s.name,
        length: s.length,
        type: s.type,
        access: s.access,
        coords: s.coords,
        speeds: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        speedsCount: 0,
        speedRangeCount: 0,
        speedRangePercent: 0,
        speedRangeScaled: 0,
        avrSpeed: 0
      }
    });
    //this.nodes = data['nodes'];
    this.nodes = data.nodes.map((s: JSONNode): TFilteredNode => {
      return {
        nodeId: s.node_id,
        coords: s.coords,
        speeds: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        speedsCount: 0,
        speedRangeCount: 0,
        speedRangePercent: 0,
        speedRangeScaled: 0,
        avrSpeed: 0
      }
    });
    this.streetTypeMap = new Map();
    let hourMap = new Map();
    // Iterate segment-stats
    this.segmentStats = new Map();
    for (let segment_stat of data['segment_stats']) {
      // add segment to segmentStats
      let segment_id = segment_stat.segment_id
      if (!this.segmentStats.has(segment_id))
        this.segmentStats.set(segment_id, [])
      // strenggenommen muss nicht ganzes segment_stat objekt gepusht werden, weil segment_id nicht notwendig  
      this.segmentStats.get(segment_id).push(segment_stat);
      // add segment_stat to hourmap
      let datetime = segment_stat.datetime
      if (hourMap.has(datetime))
        hourMap.set(datetime, hourMap.get(datetime) + segment_stat.count);
      else
        hourMap.set(datetime, 0)
    }
    // Iterate node-counts
    this.nodeStats = new Map();
    for (let node_stat of data['node_stats']) {
      // add node to nodeStats
      let node_id = node_stat.node_id
      if (!this.nodeStats.has(node_id))
        this.nodeStats.set(node_id, [])
      this.nodeStats.get(node_id).push(node_stat)
    }
    // sort hourMap & create timeTable
    hourMap = new Map([...hourMap.entries()].sort());
    this.timeTable = this.buildTimeTable(hourMap);
    if (this.timeTable.length > 0) {
      this.totalDateRange = [this.timeTable[0].dateTime, this.timeTable[this.timeTable.length - 1].dateTime];
    } else {
      this.totalDateRange = [0, 1]
    }
    console.timeEnd('PrepareData');
    //console.log('PrepareData', this.segmentStats);
    if (this.onChange) this.onChange('density');
    this.isLoaded = true;
  }

  /*
   * Filtering and Aggregation 
   */
  updateFilter({ dateRange, streetTypes, minMaxRoutes, selectedDays, hourRange, 
    nodeSpeedRange, segmentSpeedRange, nodeSpeedPercentile, segmentSpeedPercentile }) {

    let needsRecompute = false; // erweitern: needsRecomputeSpeed
    let needsRecomputeSpeed = false;

    if (this.filters.dateRange !== dateRange) {
      this.filters.dateRange = dateRange;
      needsRecompute = true;
    }
    if (this.filters.hourRange !== hourRange) {
      this.filters.hourRange = hourRange;
      needsRecompute = true;
    }
    if (this.filters.streetTypes !== streetTypes) {
      this.filters.streetTypes = streetTypes;
      needsRecompute = true;
    }
    if (this.filters.selectedDays !== selectedDays) {
      this.filters.selectedDays = selectedDays;
      needsRecompute = true;
    }
    if (this.filters.segmentSpeedRange !== segmentSpeedRange) {
      this.filters.segmentSpeedRange = segmentSpeedRange;
      needsRecomputeSpeed = true;
    }
    if (this.filters.nodeSpeedRange !== nodeSpeedRange) {
      this.filters.nodeSpeedRange = nodeSpeedRange;
      needsRecomputeSpeed = true;
    }
    if (this.filters.nodeSpeedPercentile !== nodeSpeedPercentile) {
      this.filters.nodeSpeedPercentile = nodeSpeedPercentile;
      needsRecomputeSpeed = true;
    }
    if (this.filters.segmentSpeedPercentile !== segmentSpeedPercentile) {
      this.filters.segmentSpeedPercentile = segmentSpeedPercentile;
      needsRecomputeSpeed = true;
    }
    this.filters.minMaxRoutes = minMaxRoutes;

    if (needsRecompute) {
      this.updateSegments();
      this.updateNodes();
      needsRecomputeSpeed = true;
    }
    if (needsRecomputeSpeed) {
      this.calculateNodeSpeeds();
      this.calculateSegmentSpeeds();
    }
  }

  filterStats(statsObj) {
    return (
      (!Array.isArray(this.filters.selectedDays) || this.filters.selectedDays.includes(statsObj.dow)) &&
      //(!Array.isArray(this.filters.hour) || this.filters.hour.includes(statsObj.hour)) &&
      (!Array.isArray(this.filters.dateRange) || this.filters.dateRange[0] <= statsObj.datetime) &&
      (!Array.isArray(this.filters.dateRange) || this.filters.dateRange[1] >= statsObj.datetime) &&
      (!Array.isArray(this.filters.hourRange) || this.filters.hourRange[0] <= statsObj.hour) &&
      (!Array.isArray(this.filters.hourRange) || this.filters.hourRange[1] >= statsObj.hour)
    )
  }

  /* 
  Filtering & Aggregation
  */
  updateSegments() {
    if (!this.isLoaded) return;
    console.time('Filter Segments');
    let hourMap = new Map();
    this.maxRoutesPerSegment = 0;
    this.totalSegmentSpeedCount = 0;
    this.maxSegmentSpeedCount = 0;
    this.totalSpeeds = null;
    // iterate segments
    for (let segment of this.segments) {
      let segmentId = segment.segmentId;
      segment.routeCount = 0;
      segment.speeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (!Array.isArray(this.filters.streetTypes) || this.filters.streetTypes.includes(segment.type)) {
        if (this.segmentStats.has(segmentId)) {
          let seg_counts = this.segmentStats.get(segmentId);
          for (let seg_count of seg_counts) {
            if (this.filterStats(seg_count)) {
              segment.routeCount += seg_count.count;
              segment.speeds = addArrayFast(segment.speeds, seg_count.speeds);
              incMap(hourMap, seg_count.datetime, seg_count.count);
            }
          } // segment_stats
          segment.speedsCount = segment.speeds.reduce((a, b) => a + b);
          this.maxRoutesPerSegment = Math.max(this.maxRoutesPerSegment, segment.routeCount);
          this.totalSegmentSpeedCount += segment.speedsCount;
          this.maxSegmentSpeedCount = Math.max(this.maxSegmentSpeedCount, segment.speedsCount);
          this.totalSpeeds = addArray(this.totalSpeeds, segment.speeds);
        }
      }
    } // segments
    hourMap = new Map([...hourMap.entries()].sort());
    this.timeTable = this.buildTimeTable(hourMap);
    console.timeEnd('Filter Segments');
    // Z-ordering, doesn't need to be done always, but when changing speed and density
    this.filteredSegments = this.segments.slice(0);
    for (let segment of this.filteredSegments) {
      let z = segment.routeCount / this.maxRoutesPerSegment;
      for (let i in segment.coords) {
        segment.coords[i][2] = z * 0.1;
      }
    }
  }

  updateNodes() {
    if (!this.isLoaded) return;
    console.time('Filter Nodes');
    this.totalNodeSpeedCount = 0;
    this.maxNodeSpeedCount = 0;
    // iterate nodes
    for (let node of this.nodes) {
      let nodeId = node.nodeId;
      node.routeCount = 0;
      node.speeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (this.nodeStats.has(nodeId)) {
        let node_counts = this.nodeStats.get(nodeId);
        for (let node_count of node_counts) {
          if (this.filterStats(node_count)) {
            node.routeCount += node_count.count;
            node.speeds = addArrayFast(node.speeds, node_count.speeds);
          }
        } // node_stats
        node.speedsCount = node.speeds.reduce((a, b) => a + b);
        this.totalNodeSpeedCount += node.speedsCount;
        this.maxNodeSpeedCount = Math.max(this.maxNodeSpeedCount, node.speedsCount);
      }
    } // nodes
    this.filteredNodes = this.nodes.slice(0);
    console.timeEnd('Filter Nodes');
  }

  /* 
  Speed related calculations
  */
  calculateNodeSpeeds() {
    if (!this.filteredNodes) return;
    console.time("calculateNodeSpeeds");
    for (let node of this.filteredNodes) {
      const [minSpeed, maxSpeed] = this.filters.nodeSpeedRange;
      node.speedRangeCount = node.speeds.slice(minSpeed, maxSpeed).reduce((a: number, b: number) => a + b, 0);
      node.speedRangePercent = node.speedRangeCount / node.speedsCount;
      node.speedRangeScaled = node.speedRangeCount / this.maxNodeSpeedCount;
      node.coords[2] = 1 * node.speedRangeScaled;
      node.avrSpeed = speedMedian(node.speeds, this.filters.nodeSpeedPercentile);
    }
    console.timeEnd("calculateNodeSpeeds");
  }

  calculateSegmentSpeeds() {
    if (!this.filteredSegments) return;
    console.time("calculateSegmentSpeeds");
    for (let segment of this.filteredSegments) {
      const [minSpeed, maxSpeed] = this.filters.segmentSpeedRange;
      segment.speedRangeCount = segment.speeds.slice(minSpeed, maxSpeed).reduce((a, b) => a + b, 0);
      segment.speedRangePercent = segment.speedRangeCount / segment.speedsCount;
      segment.speedRangeScaled = segment.speedRangeCount / this.maxSegmentSpeedCount;
      segment.avrSpeed = speedMedian(segment.speeds, this.filters.segmentSpeedPercentile);
    }
    console.timeEnd("calculateSegmentSpeeds");
  }
}
