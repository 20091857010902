import React, { useMemo } from "react"
//import ReactECharts from 'echarts-for-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent, TooltipComponent, TitleComponent, DatasetComponent, } from 'echarts/components';
echarts.use(
  [BarChart, LineChart, CanvasRenderer, GridComponent]
);
/*
ToDo:
- Unterscheidung nach Modes nicht optimal
- Reskalierung der X-Achse nervig
- Farbcodierung sinnvoll? Absolut oder relativ?
*/

// helper for summing up counts depending on fieldname
function addCounts(arr, obj, fieldname) {
  let key = parseInt(obj[fieldname]);
  if (arr[key])
    arr[key] += obj.count;
  else
    arr[key] = obj.count;
  return arr;
}

export default function TimeGraph({
  data, colorPalette, mode
}) {
  // console.log("TImeline", mode);

  const [values, axis, maxValue] = useMemo(() => {
    let days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    let axis = [1, 2, 3, 4, 5];
    let values = [1, 1, 1, 1, 1];
    let maxValue = 0;
    if (data) {
      if (mode === 'dayByHours') {
        values = data.reduce((arr, obj) => addCounts(arr, obj, 'hourOfDay'), []);
        axis = Array.from(values.keys());
      }
      else if (mode === 'weekByDays') {
        values = data.reduce((arr, obj) => addCounts(arr, obj, 'dayOfWeek'), []);
        axis = days;
      }
      else if (mode === 'weekByHours') {
        values = data.reduce((arr, obj) => addCounts(arr, obj, 'hourOfWeek'), []);
        axis = Array.from(values.keys());
        axis = axis.map((v, i) => {
          let hour = i % 24;
          let day = Math.floor(i / 24);
          if (hour === 0)
            return days[day];
          else
            return hour;
        });
      }
      else if (mode === 'all') {
        values = data.map(d => d.count);
        axis = data.map(d => d.dateTime);
      }
      maxValue = values.reduce((a, b) => Math.max(a, b), 0);
      if (mode === 'all') {
        values = data.map(d => [d.dateTime, d.count]);
      }
    }
    return [values, axis, maxValue];
  }, [mode, data]);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={{
        title: { show: false },
        toolbox: { show: false },
        grid: { left: "48", top: "7", bottom: "18", right: "2" },
        xAxis: {
          type: (mode === 'all') ? 'time' : 'category',
          data: axis,
          axisLabel: {
            interval: (mode === 'weekByHours') ? 23 : 'auto',
            fontWeight: 'bold'
          },
          axisTick: {
            interval: (mode === 'weekByHours') ? 5 : 'auto'
          },
        },
        yAxis: {
          type: 'value',
          max: maxValue,
        },
        series: {
          data: values,
          type: 'bar',
        },/*
        visualMap: {
          type: 'continuous',
          min: 0,
          max: maxValue,
          show: false,
          calculable: true,
          itemWidth: 4,
          right: -8,
          inRange: {
            color: colorPalette.getColors(6),
          },
        }*/
      }}
      style={{ width: "100%", height: "120px" }}
    />
  )
}