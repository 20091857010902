import './MapView.css'
import React, { useEffect, useMemo, useState } from 'react';
import TrafficMap from './Map/TrafficMap';
import FilterPanel from './MapPanel/MapPanel';
import TimeLine from './Timeline/TimeLine';
import { ColorPalettes } from 'CityData/ColorPalettes'
import Spinner from 'components/Spinner';
import { useMapFilter, useMapSettings } from './MapState';
import SpeedHistogram from './SpeedHistogram/SpeedHistogram';

/* ToDo:
  - Im Speed-Mode Histogramm unten anzeigen
  - TotalDateRange (und maxRoutesPerSegment und weitere)
  - SpeedSegment und SpeedNode Layer schaltbar (in MapState)
  - Manche Settings und Filter für Traffic, SpeedNode und SpeedSegment verschieden
  - Speed: Verschiedene Metriken (Average, Median, Slow, Fast, )
  - ggf. Setting für "AllSegmentsLayer" im Hintergrund (für helle Karten)
  - Spinner / Indicator für Processing
*/

export default function MapView({ cityData, mapMode }) {

  const [filter, dispatchFilter] = useMapFilter(cityData);
  const [settings, dispatchSettings] = useMapSettings();
  // console.log('MapView', settings);

  useEffect(() => {
    if (cityData.isLoaded)
      dispatchFilter({ type: 'setDateRange', value: cityData.totalDateRange });
  }, [cityData.isLoaded, cityData.totalDateRange, cityData.cityId, dispatchFilter]);

  useEffect(() => {
    if (cityData.isLoaded)
      dispatchFilter({ type: 'setMinMaxRoutes', value: [0, cityData.maxRoutesPerSegment] });
  }, [cityData.isLoaded, cityData.maxRoutesPerSegment, cityData.cityId, dispatchFilter]);

  useMemo(() => {
    cityData.updateFilter(filter);
  }, [filter]);

  return (
    <>
      <div id="mapview" >
        <div className="map-sidebar">
          <div className="widget">
            <h2>Einstellungen</h2>
            <FilterPanel
              className="filterpanel"
              filter={filter}
              filterDispatcher={dispatchFilter}
              settings={settings}
              settingsDispatcher={dispatchSettings}
              data={cityData}
              mapMode={mapMode}
            />
          </div>
        </div>
        <main className="widget" >
          <TrafficMap
            data={cityData}
            settings={settings}
            filters={filter}
            mapMode={mapMode}
          />
        </main>
        {mapMode === 'density' ?
          <footer className="widget" >
            {!cityData.isLoaded ? <Spinner /> :
              <>
                <TimeLine
                  data={cityData.timeTable}
                  palette={ColorPalettes[settings.palette]} />
              </>
            }
          </footer>
          : null}
          {mapMode === 'speed' ?
            <footer className="widget" >
              {!cityData.isLoaded ? <Spinner /> :
                <>
                  <SpeedHistogram
                    data={cityData.totalSpeeds}
                    palette={ColorPalettes[settings.palette]} />
                </>
              }
            </footer>
            : null}
      </div>
    </>
  );
}